.App {
  display: grid;
}

body {
  background-image: url("./assets/retina_wood.png");
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  box-sizing: border-box;

  /* required rules to fix a background positioning issue with Material UI */
  position: absolute;

  overflow-x: hidden;
  overflow-y: auto !important;
}
